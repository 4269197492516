import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"session-list__header\">\n  <h1 class=\"page-title\">{{t \"pages.sessions.list.header.title\"}}</h1>\n  <div class=\"session-list-header__actions\">\n    <PixButtonLink @route=\"authenticated.sessions.new\">\n      {{t \"pages.sessions.list.header.session-creation\"}}\n    </PixButtonLink>\n    {{#if this.shouldRenderImportTemplateButton}}\n      <PixButtonLink @route=\"authenticated.sessions.import\">\n        {{t \"pages.sessions.list.header.session-multiple-creation-edition\"}}\n      </PixButtonLink>\n    {{/if}}\n  </div>\n</div>", {"contents":"<div class=\"session-list__header\">\n  <h1 class=\"page-title\">{{t \"pages.sessions.list.header.title\"}}</h1>\n  <div class=\"session-list-header__actions\">\n    <PixButtonLink @route=\"authenticated.sessions.new\">\n      {{t \"pages.sessions.list.header.session-creation\"}}\n    </PixButtonLink>\n    {{#if this.shouldRenderImportTemplateButton}}\n      <PixButtonLink @route=\"authenticated.sessions.import\">\n        {{t \"pages.sessions.list.header.session-multiple-creation-edition\"}}\n      </PixButtonLink>\n    {{/if}}\n  </div>\n</div>","moduleName":"pix-certif/components/sessions/panel-header.hbs","parseOptions":{"srcName":"pix-certif/components/sessions/panel-header.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class PanelHeader extends Component {
  @service featureToggles;

  get shouldRenderImportTemplateButton() {
    return this.featureToggles.featureToggles.isMassiveSessionManagementEnabled;
  }
}
