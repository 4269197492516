import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"no-session-panel\">\n  <div class=\"no-session-panel__explain-create-session\">\n    <img src=\"{{this.rootURL}}/icons/empty-list-session.svg\" alt=\"Vous pouvez créer des sessions de certifications.\" />\n    <h1 class=\"page-title\">Créer ma première session de certification</h1>\n\n    <div class=\"no-session-panel__link-to-create\">\n      <PixButtonLink @route=\"authenticated.sessions.new\">\n        {{t \"pages.sessions.list.empty.session-creation\"}}\n      </PixButtonLink>\n      {{#if this.shouldRenderImportTemplateButton}}\n        <PixButtonLink @route=\"authenticated.sessions.import\">\n          {{t \"pages.sessions.list.empty.session-multiple-creation\"}}\n        </PixButtonLink>\n      {{/if}}\n    </div>\n  </div>\n</div>", {"contents":"<div class=\"no-session-panel\">\n  <div class=\"no-session-panel__explain-create-session\">\n    <img src=\"{{this.rootURL}}/icons/empty-list-session.svg\" alt=\"Vous pouvez créer des sessions de certifications.\" />\n    <h1 class=\"page-title\">Créer ma première session de certification</h1>\n\n    <div class=\"no-session-panel__link-to-create\">\n      <PixButtonLink @route=\"authenticated.sessions.new\">\n        {{t \"pages.sessions.list.empty.session-creation\"}}\n      </PixButtonLink>\n      {{#if this.shouldRenderImportTemplateButton}}\n        <PixButtonLink @route=\"authenticated.sessions.import\">\n          {{t \"pages.sessions.list.empty.session-multiple-creation\"}}\n        </PixButtonLink>\n      {{/if}}\n    </div>\n  </div>\n</div>","moduleName":"pix-certif/components/no-session-panel.hbs","parseOptions":{"srcName":"pix-certif/components/no-session-panel.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class PanelHeader extends Component {
  @service featureToggles;

  get shouldRenderImportTemplateButton() {
    return this.featureToggles.featureToggles.isMassiveSessionManagementEnabled;
  }
}
